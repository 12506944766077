.personal, .skills {
    color: #36454F;
    text-align: justify;
}

.bold {
    color: black;
}

.skills {
    font-weight: lighter;
    color: #8c9ba5;
}

.personal {
    font-size: 17px;
}

.skill-value {
    font-weight: bolder;
    color: #36454F;
}

div.contact {
    margin-right: 10%;
}

.fw-bold {
    font-family: "LexendBold";
}