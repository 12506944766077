.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.desc {
    text-align: justify;
    font-size: small;
    font-family: "LexendLight";
}

.image-project {
    width: 100%;
}