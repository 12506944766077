.posts {
    margin-right: 2%;
    margin-bottom: 2%;
}

h3 {
    color: #05386b;
    font-family: "LexendBold";
    font-size: x-large;
}

.read-more {
    background-color: #05386b !important;
    display: inline-block;
    color: white !important;
}

.card-image {
    height: 250px;
    width: 250px;
    display: block;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.card-h {
    height: 100% !important;
    display: flex;
    position: relative;

}

.mt-auto {
    margin-top: auto !important;
}

.card-body {
    flex: 1 1 auto;
}

.title {
    font-family: "LexendBold";
    font-size: large;
    color: #05386b;
}

.subtitle {
    font-family: "LexendLight";
    font-size: smaller;
    color: #8c9ba5;
}