@font-face {
    font-family: 'Mono';
    src: local('Mono'), url(./assets/mono.ttf) format('truetype');
}
  
@font-face {
    font-family: 'Asap';
    src: local('Asap'), url(./assets/asap.ttf) format('truetype');
}

@font-face {
    font-family: 'LexendBold';
    src: local('Lexend-Bold'), url(./assets/Lexend-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'LexendLight';
    src: local('Lexend-Light'), url(./assets/Lexend-Light.ttf) format('truetype');
}

.navbar-text {
    font-family: 'Mono';
    font-size: large;
    color: #dedcdc;
}

.navbar-bg {
    background-color: #03045e;
    padding-left: 9%;
}

.navbar-brand {
    font-family: 'Mono';
    font-size: x-large;
    color: #dedcdc;
}

p, h2 {
    font-family: "LexendBold";
}

p {
    font-size: 18px;
}

.badge {
    font-family: "LexendLight";
    font-size: 12px;
}

h2 {
    font-size: 100px;
}

h3, h4, h5 {
    font-family: "LexendBold";
}

li {
    font-family: "LexendLight";
    font-size: medium;
}

h4.title {
    color: #05386b;
}

h3.title {
    color: #05386b;
    font-family: "LexendBold";
    font-size: x-large;
}

.subtitles {
    font-size: large;
    font-style: italic;
    font-weight: lighter;
}

.tagline {
    font-size: small;
    color: #8c9ba5;
}

.button-content {
    /* background-color: #03045e; */
    font-family: 'LexendLight';
    margin-top: 5%;
}

.body {
    padding-left: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-right: 10%;
}

.content {
    text-align: center;
    margin-left: 5%;
    margin-top: 5%;
    color: #8c9ba5;
}

.profile-tabs .nav-link {
    color: #05386b !important;
}

.nav-link.active {
    background-color: #05386b !important;
    color: #dfe3e6 !important;
}

.linkedin, .email {
    width: 20px;
    background-color: white;
    background-size: 30px 3px;
    margin: auto;
    box-sizing: border-box;
}

.social-network {
    padding-left: 1%;
}