.duration, .tech {
    color: #8c9ba5;
    font-size: small;
}

.tasks {
    color: #636565;
    font-size: medium;
    font-family: "LexendLight";
}

h5.position {
    font-style: bold;
}

.techstack {
    /* overflow: auto; */
    flex-wrap: wrap;
}